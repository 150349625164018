import { render, staticRenderFns } from "./userspread.vue?vue&type=template&id=f81bd410&scoped=true&"
import script from "./userspread.vue?vue&type=script&lang=js&"
export * from "./userspread.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f81bd410",
  null
  
)

export default component.exports